.mobileMenu{
    width:  100%;
    display: inline-block;
    position: fixed;
    left: 0px;
    top: 70px;
    bottom: 0px;
    z-index: 9000;
    background: rgba(255,255,255,0.95);
    padding:15px;
	& ul{
		list-style: none;
		margin:0;
		padding: 0;
		text-align: center;
	}
	& li{
		height: 100px;
		& a{
			font-size:$font-size-large*2;
		}
	}

}
