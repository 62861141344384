.main-nav{
	height:70px;
	background:#fff;
	position:fixed;
	top:0px;
	left:0px;
	width:100%;
	z-index:5000;
	& .container{
		 position: relative;
		  top: 50%;
		  left:50%;
		  transform: translate(-50%,-50%);
		  margin:0px;
	}
	& .navbar-brand{
		padding-left:0px;
		font-size:$font-size-large;

	}
	& img{
		max-width:100%;
		max-height: 100%;
		display:inline-block;
	}
}
.nav > li > a:hover,  .nav > li > a:focus{
		background-color:inherit;
}