.features{
	margin-top:60px;
	padding-top:60px;
	padding-bottom:60px;
		background:#fff;
	& img.icon{
		max-width:200px;
	}
	& p{
		max-width: 80%;
		margin-left:auto;
		margin-right:auto;
	}
}