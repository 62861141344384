@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "components";



body{
	padding-top:70px;
	position:relative;
}




.banner{
		color:$gray-light;
		z-index:1;
		& .h1{
		    font-size: 80px;
		    font-weight: 800;
		    max-width: 700px;
		    margin-bottom: 60px;
		    line-height: 1;
		    .text-center &, &.text-center{
		    	margin-left:auto;
		    	margin-right:auto;
		    }
		}

		& .h3{
		    font-size: 1.45em;
		    font-weight: 200;
		    margin-bottom: 75px;
		    margin-top: 0;
		    line-height: 2em;
		}
	}

.top{
		background-image:url('../images/background.png');
	padding-top:60px;
	padding-bottom:60px;
	position:relative;

}

.footer{
	margin-top:60px;
	padding-top:60px;
	padding-bottom:60px;
	background:$gray-lighter;

}


 @media only screen and (max-width: $screen-xs-min) {
    .banner{
    	& .h1{
		    font-size: 60px;
		    max-width: $screen-xs-min;
		}
    }
  }
