.customers{
	margin-top:60px;
	padding-top:60px;
	padding-bottom:60px;
		background-image:url('../images/background.png');
	& blockquote{
		padding:0px 32px;
		margin:0px;
		font-size:50px;
		& footer{
			font-size:30%;
			color: lighten($text-color, 20%);
		}
	}
}

@media only screen and (max-width: $screen-xs-min) {
	.customers{
		& blockquote{
			font-size:40px;
			& footer{
				font-size:50%;
				color: lighten($text-color, 20%);
			}
		}
	}
  }