.contact{
	margin-top:60px;
	padding-top:60px;
	padding-bottom:60px;
	background-color:#fff;
}

@media only screen and (max-width: $screen-xs-min) {
	.contact .h1{
		font-size:45px;
	}
}